import React from 'react'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import doenerGuel from '../assets/images/doener-guel.jpg'
import pizzaGuel from '../assets/images/pizza-guel.jpg'
import salatGuel from '../assets/images/salata-guel.jpg'
import schnitzelGuel from '../assets/images/schnitzel.guel.jpg'
import lahmacunGuel from '../assets/images/lahmacun-guel.png'
import hamburgerGuel from '../assets/images/hamburger-guel.jpg'
import Order from '../components/Order';

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>

                <Banner />

                <div id="main">
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>Über Gül Kebap und Pizza Haus Lambrecht(Pfalz)</h2>
                            </header>
                                <p>
                                    Wir von Gül Kebap und Pizza Haus Lambrecht bieten Gerichte von hervorragender Qualität und laden Sie herzlich ein, unsere köstliche Küche kennenzulernen.
                                    Der Schlüssel zu unserem Erfolg ist einfach: Wir bieten hochwertige Gerichte, die stets den Gaumen erfreuen. Wir sind stolz darauf, unseren Kunden schmackhafte und authentische Gerichte zu servieren. zB.: .
                                    Genießen Sie wahre Gaumenfreuden. Wählen Sie dazu ein Getränk aus. Und vor allem: Entspannen Sie sich! Wir bedanken uns herzlich bei Ihnen für Ihre fortwährende Unterstützung.
                                </p>
                                <Order />
                        </div>
                    </section>
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${doenerGuel})`}}>
                            <header className="major">
                                <h3>Döner Gerichte</h3>
                                <p>Saftiges Fleisch</p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${pizzaGuel})`}}>
                            <header className="major">
                                <h3>Pizza </h3>
                                <p>von Steinoffen</p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${salatGuel})`}}>
                            <header className="major">
                                <h3>Salat</h3>
                                <p>knackig, frisch und gesund</p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${schnitzelGuel})`}}>
                            <header className="major">
                                <h3>Schnitzel</h3>
                                <p>Alle frisch zubereitet</p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${lahmacunGuel})`}}>
                            <header className="major">
                                <h3>Lahmacun</h3>
                                <p>MEHR ALS NUR TÜRKISCHE PIZZA</p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${hamburgerGuel})`}}>
                            <header className="major">
                                <h3>Hambgurger</h3>
                                <p>Saftige Burger</p>
                            </header>
                        </article>
                    </section>
                    <section id="opening">
                        <div className="inner">
                            <header className="major">
                                <h2>Öffnungszeiten</h2>
                            </header>
                            <h3>Öffnungszeiten</h3>
                            <p>
                                Mo.: Ruhetag <br />
                                Di. - Sa. : 11.00 – 22.00 Uhr <br />
                                So. & Feiertag : 12.00 – 22.00 Uhr
                            </p>
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex