import React from 'react'
import Order from './Order'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Gül Kebap und Pizza Haus Lambrecht(Pfalz)</h1>
            </header>
            <div className="content">
                <h2>BESTE DÖNER, PIZZA, SALAT... IN Lambrecht(Pfalz) UND UMGEBUNG</h2>
            </div>
            <div className="content">
                <Order fixed />
            </div>
        </div>
        <div className="service">
            <h2>Wir bieten Abholung{ props.delivery ? ' und Lieferung': '' } an</h2>
        </div>
    </section>
)

export default Banner
